




















import {
  Component,
} from 'vue-property-decorator';
import FormBase from '../FormBase';
import BarChartData from './modelsBar';

// todo: title & icon not in chartData

@Component
export default class FormBar extends FormBase {
  data: BarChartData = {
    title: '',
    description: '',
    icon: '',
    seriesFields: [''],
    xAxisField: '',
    colors: [''],
  };
}
