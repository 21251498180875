import {
  Vue,
  Component,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component
export default class FormBase extends Vue {
  @Prop({ required: true }) fields!: string[];

  @Prop() currentData?: any;

  data: any | null;

  created(): void {
    if (this.currentData) this.data = this.currentData;
  }

  @Watch('data', { deep: true })
  onDataChange(data: any): void {
    this.$emit('dataChange', data);
  }
}
